/* eslint-disable @typescript-eslint/no-var-requires */

import { getState, getAuthState } from "@sparky/framework";
import { SPARKY_NAV3_LEFT_NAV_EXTPOINT } from '@sparky/framework/extpoints';
import { REPORTING_ASSOCIATED_APPS } from "../ReportingAssociatedApps";
import type { ExtensionsConfig, SparkyLeftNavConfig } from "@sparky/framework/extpoints";

function getReportsById () {
  const { reporting = {} } = getState() || {};
  return reporting?.reportsById;
}
function hideReporting () {
  // disable dashboards/reporting for enterprise dlp or casb only tenants
  // load reporting pages only if associated apps are present
  const instances = getAuthState()?.instances;
  const found = REPORTING_ASSOCIATED_APPS.some(app => instances?.isRunningStatus(app));
  if (!found) {
    return true;
  }
  return false;
}

// below is for nav v3 using extensions
export default {
  point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  configs: [
    {
      id: 'netsec.vnr.nav.security.insights',
      config: {
        stateLoaders: ['auth', 'reporting'],
        navigation: () => [{
          key: 'security-insights',
          path: '/security-insights',
          title: 'Security Insights',
          children: [
            {
              key: 'unified_network_activity_report',
              title: 'Activity Insights',
              path: '/security-insights/activity_insights',
              avail: () => {
                if (hideReporting()) {
                  return false;
                }
                const reportById = getReportsById();
                return reportById ? reportById.unified_network_activity_report.allowed : false;
              }
            },
            {
              key: 'threats-intelligence',
              title: 'Threats Intelligence',
              path: '/security-insights/threats-intelligence',
              avail: () => {
                if (hideReporting()) {
                  return false;
                }
                const reportById = getReportsById();

                return reportById ? reportById.ioc_report.allowed : false;
              }
            },
            {
              key: 'executive_report',
              title: 'Executive Summary',
              path: '/security-insights/executive',
              // replacePaths: '/dashboards/executive',
              avail: () => {
                const reportById = getReportsById();
                return reportById ? reportById?.executive_report?.allowed : false;
              }
            },
            {
              key: 'wildfire_report',
              title: 'WildFire',
              path: '/security-insights/wildfire',
              replacePaths: '/dashboards/wildfire',
              avail: () => {
                const reportById = getReportsById();
                return reportById ? reportById?.wildfire_report?.allowed : false;
              }
            },
            {
              key: 'posture',
              title: 'POSTURE',
              path: '/security-insights/posture',
              children: [
                {
                  key: 'best_practices_report',
                  title: 'Best Practices',
                  path: '/security-insights/bpa',
                  replacePaths: '/dashboards/bpa',
                  avail: () => {
                    const reportById = getReportsById();
                    return reportById ? reportById?.best_practices_report?.allowed : false;
                  }
                }
              ]

            }
          ]
        }],
        isAllowed: () => true
      }
    },
    {
      id: 'netsec.vnr.nav.logviewer',
      config: {
        stateLoaders: ['auth', 'reporting'],
        navigation: () => [{
          key: 'logviewer',
          title: 'Log Viewer',
          path: '/logviewer',
          replacePaths: '/incidents-alerts/logviewer',
          avail: () => {
            const reportById = getReportsById();
            return reportById ? reportById.log_viewer.allowed : false;
          }
        }],
        isAllowed: () => {
          return true;
        }
      }
    }
  ]
} satisfies ExtensionsConfig<SparkyLeftNavConfig>;
