export type IconParams = {
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const CanvasIcon = (props: IconParams) => {
  const { width = "32", height = "32", className = "", style = {} } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <g transform="translate(0 0.915) translate(3.655 0) translate(-2.33 0) matrix(0.0834664 0 0 0.0834664 0.329651 0.404444)">
        <rect
          fill="none"
          height="56"
          id="svg_2"
          rx="8"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          width="56"
          x="24"
          y="100"
        />
        <rect
          fill="none"
          height="64"
          id="svg_3"
          rx="8"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          width="64"
          x="160"
          y="40"
        />
        <rect
          fill="none"
          height="64"
          id="svg_4"
          rx="8"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          width="64"
          x="160"
          y="152"
        />
        <line
          fill="none"
          id="svg_5"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
          x1="80"
          x2="120"
          y1="128"
          y2="128"
        />
        <path
          d="m160,184l-16,0a23.9,23.9 0 0 1 -24,-24l0,-64a23.9,23.9 0 0 1 24,-24l16,0"
          fill="none"
          id="svg_6"
          stroke="currentcolor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        />
      </g>
    </svg>
  );
};

export const DashboardIcon = (props: IconParams) => {
  const { width = "24", height = "24", className = "", style = {} } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z" />
    </svg>
  );
};

export const HomeIcon = (props: IconParams) => {
  const { width = "24", height = "24", className = "", style = {} } = props;
  return (
    <svg
      height={height}
      width={width}
      className={className}
      style={style}
      enableBackground="new 0 0 24 24"
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M30.854,16.548C30.523,17.43,29.703,18,28.764,18H28v11c0,0.552-0.448,1-1,1h-6v-7c0-2.757-2.243-5-5-5  s-5,2.243-5,5v7H5c-0.552,0-1-0.448-1-1V18H3.235c-0.939,0-1.759-0.569-2.09-1.451c-0.331-0.882-0.088-1.852,0.62-2.47L13.444,3.019  c1.434-1.357,3.679-1.357,5.112,0l11.707,11.086C30.941,14.696,31.185,15.666,30.854,16.548z"
        id="XMLID_219_"
      />
    </svg>
  );
};

export const WidgetIcon = (props: IconParams) => {
  const { width = "32", height = "32", className = "", style = {} } = props;
  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentcolor"
    >
      <path d="m21.36,18.78l-1.31,0l0,-14.06a0.49,0.5 0 0 0 -0.49,-0.5l-3.72,0a0.49,0.5 0 0 0 -0.49,0.5l0,14.06l-1.07,0l0,-8.6a0.49,0.5 0 0 0 -0.49,-0.5l-3.73,0a0.49,0.5 0 0 0 -0.49,0.5l0,8.6l-1.05,0l0,-4.48a0.5,0.51 0 0 0 -0.49,-0.5l-3.74,0a0.5,0.51 0 0 0 -0.49,0.5l0,4.48l-1.14,0a0.49,0.5 0 0 0 0,1.01l18.72,0a0.49,0.5 0 0 0 0,-1.01zm-16.59,0l0,-3.92l2.74,0l0,3.92l-2.74,0zm5.77,0l0,-8.1l2.74,0l0,8.1l-2.74,0zm5.77,0l0,-13.56l2.74,0l0,13.56l-2.74,0z" />
    </svg>
  );
};
