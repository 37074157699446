/* eslint-disable @typescript-eslint/no-var-requires */
import * as sparkyFramework from "@sparky/framework";
import { SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT } from '@sparky/framework/extpoints';
import type { ExtensionsConfig, StateLoaderConfig } from "@sparky/framework/extpoints";
import { initV3Nav } from "../init";

export default {
  point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
  configs: [{
    id: 'sparky.nav.state.loaders.reporting',
    config: {
      name: 'reporting', // this is the key, means it can access by state.main
      requireFetch: true,
      loader: async () => {
        const result = await initV3Nav(sparkyFramework, sparkyFramework?.getMicroAppVars?.("reporting_oneapp"));
        return result;
      }

    }
  }]
} satisfies ExtensionsConfig<StateLoaderConfig>;
