export const navToRbacPermissionNameMapping = {
  "ai_security": ["vnr.ai_runtime"]
}

type NavKey = keyof typeof navToRbacPermissionNameMapping;

export enum AccessTypes {
  WRITE = "write",
  READ = "read",
  NO_ACCESS = "no-access"
}

export const getRbacAccessType = (key: NavKey, authData): AccessTypes => {
  const permissionSets = navToRbacPermissionNameMapping[key];

  if (!permissionSets || permissionSets.length === 0) {
      return AccessTypes.NO_ACCESS; //no entry for the nav key, so assume it's not visible
  }

  const accessTypes = permissionSets.map(pSet => {
      return authData?.access?.checkPermissionSet(pSet);
  })

  if (accessTypes.includes("write")) {
      return AccessTypes.WRITE;
  } else if (accessTypes.includes("read")) {
      return AccessTypes.READ;
  } else {
      return AccessTypes.NO_ACCESS;
  }
}

export const hasRBACAccess = (key: NavKey, authData): boolean => {
  const accessType = getRbacAccessType(key, authData);
  return accessType !== AccessTypes.NO_ACCESS;
}