import { SPARKY_NAV3_LEFT_NAV_EXTPOINT } from "@sparky/framework/extpoints";
import { hasAIAccess, hasNetsecUI } from "src/utils/navAvailUtils";
import type { ExtensionsConfig, SparkyLeftNavConfig } from "@sparky/framework/extpoints";

export default {
    point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    configs: [
        {
            id: "netsec.cc.nav.security.insights",
            config: {
                stateLoaders: ["auth"],
                navigation: () => [{
                    key: "security-insights",
                    path: "/security-insights",
                    children: [
                        {
                            key: "ac-home",
                            title: "AI Access",
                            path: "/security-insights/ai-access",
                            avail: hasAIAccess,
                        }
                    ]
                }],
                isAllowed: () => true
            }
        },
        {
            id: "netsec.cc.nav.command.center",
            config: {
                navigation: () => [
                    {
                        key: "home",
                        title: "Command Center",
                        path: "/home",
                        avail: hasNetsecUI,
                    }
                ],
                isAllowed: () => true
            }
        }

    ]
} as ExtensionsConfig<SparkyLeftNavConfig>;
