/* eslint-disable @typescript-eslint/no-var-requires */
import getAppTenantIdTsg from "./utils/get-app-tenant-id-tsg";
import getAppTenantId from "./utils/get-app-tenant-id";
import getDIAppTenantRegion from "./utils/getAppTenantRegion";
import { HOST_APP_DI, HOST_APP_FAWKES } from "@reporting-n-viz/vnr-entrypoint/dist/host-app";
import { aiopsAuthDataMutation } from "./utils/aiops-authdata-mutation";
import { getAuthState } from "@sparky/framework";

function getParams(sparkyFramework: any, vars: any, isV3Nav: boolean | undefined, callback: (params: object) => void): Parameters< (apiEndpoint: string, params: object, callback: (params: object) => void) => void > {
  const getCsIdTsg = require("./utils/get-cs-id-tsg").default;
  const getCsId = require("./utils/get-cs-id").default;
  const getLsTenantIdTsg = require("./utils/get-ls-tenant-id-tsg").default;
  const getLsTenantId = require("./utils/get-ls-tenant-id").default;
  const getLsTenantLogData = require("./utils/get-ls-tenant-log-data").default;
  const getInstanceTier = require("./utils/get-instance-tier").default;
  const getSparkyMainState = require("./utils/get-sparky-main-state").default;
  const getManagedAppName = require("./utils/get-managed-app-name").default;
  const getPaParams = require("./utils/get-pa-params").default;
  const getSdwanOnlyFlag = require("./utils/get-sdwan-only-flag").default;
  const getMfeProps = require("./utils/get-mfe-properties").default;
  const hasTsgSupport = sparkyFramework.hasTsgSupport();
  const main = getSparkyMainState(
    hasTsgSupport,
    sparkyFramework.getAuthState,
    sparkyFramework.getMainState,
    sparkyFramework.StartupManager
  );
  const { paiFQDN } = getPaParams(hasTsgSupport, main, sparkyFramework.StartupManager);
  let appTenantRegion = main?.tenant?.info?.region;
  let authData = hasTsgSupport
    ? sparkyFramework.getAuthState()
    : sparkyFramework.getAuthData(main.tenant, main.authInfo, main.loggedInUser);
  const isPAEnabled =
          main?.tenant?.pa_enabled ?? authData?.instances?.isFawkesRunning ?? false;
  const currentInstanceTier = getInstanceTier()?.frameworkInstance;
  const isHybridFramework = getInstanceTier()?.isHybridFramework;
  const hasSCMInstance = getInstanceTier()?.hasSCMInstance;
  const isSDWANOnlyTenant = getSdwanOnlyFlag(authData, currentInstanceTier);
  const hasCDL = authData?.instances?.isRunningStatus("logging_service");
  const paiFeatures = sparkyFramework.getState()?.saseIAMainState?.cosmosFeatures;
  // support new sku/instance
  const isSCMInstanceAvail = authData?.instances?.isRunningStatus("strata_cloud_manager");
  const lsTenantLogData = getLsTenantLogData(authData, isSCMInstanceAvail);
  let managedAppName = getManagedAppName(
    hasTsgSupport,
    sparkyFramework.getAuthState,
    sparkyFramework.StartupManager
  );
  let di;
  let prismaAccess;
  const adem = extractAdemParams(sparkyFramework, authData);
  const appTenantIdPrismaAccess = hasTsgSupport
    ? getAppTenantIdTsg(authData, "prisma_access")
    : undefined;
  if (appTenantIdPrismaAccess && isPAEnabled) {
    prismaAccess = {
      appTenantId: appTenantIdPrismaAccess,
      appTenantRegion,
      appType: managedAppName
    };
  }

  const aiopsAppId = isSCMInstanceAvail
    ? "strata_cloud_manager"
    : currentInstanceTier === "premium"
      ? "strata_insights"
      : "strata_insights_free";
  if (
    hasTsgSupport &&
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (authData.instances.some(({ app_id }: any) => app_id === aiopsAppId) || isSDWANOnlyTenant)
  ) {
    managedAppName = isSDWANOnlyTenant ? "cgx" : "strata_insights";
    authData = aiopsAuthDataMutation(authData, currentInstanceTier, isSCMInstanceAvail);
    appTenantRegion = getDIAppTenantRegion(managedAppName, authData);
    di = {
      appTenantId: getAppTenantIdTsg(authData, managedAppName),
      appTenantRegion,
      appType: managedAppName
    };
  }
  const hostApp = [
    "strata_insights_free",
    "strata_insights",
    "strata_insights_ngfw",
    "cgx",
    "strata_cloud_manager"
  ].includes(managedAppName)
    ? HOST_APP_DI
    : HOST_APP_FAWKES;
  const appTenantId = hasTsgSupport
    ? getAppTenantIdTsg(authData, managedAppName)
    : getAppTenantId(main);

  return [
    vars.api_server_url,
    {
      tokenManager: hasTsgSupport
        ? null
        : sparkyFramework.getTokenManager(main.tenant, main.authInfo, main.loggedInUser),
      csId: hasTsgSupport ? getCsIdTsg(authData, managedAppName) : getCsId(main),
      tsgId: authData.tsg_id,
      appsToTenantMap: new Map([[managedAppName, new Set([appTenantId])]]),
      hasTsgSupport,
      getCurrentTsgToken: sparkyFramework.getCurrentTsgToken,
      hostApp,
      managedAppName,
      appTenantId,
      authData,
      loggedInUser: main?.loggedInUser,
      appTenantRegion: appTenantRegion || "us",
      isEarlyAccessNgfwTenant: main?.tenant?.is_early_access_ngfw_tenant || false,
      isSDWANOnlyTenant,
      lsTenantId: hasTsgSupport ? getLsTenantIdTsg(authData) : getLsTenantId(main),
      currentInstanceTier,
      isHybridFramework,
      hasSCMInstance,
      hasCDL,
      notTelemetryTenant: lsTenantLogData,
      di,
      prismaAccess,
      adem,
      paiFQDN,
      mfeProps: getMfeProps(),
      paiFeatures,
      platformRegion: getAuthState()?.instances?.getPlatformRegion(),
      isV3Nav
    },
    callback
  ];
}

function extractAdemParams(sparkyFramework: any, authData: any) {
  const apiServerUrl = sparkyFramework?.getMicroAppVars("dem")?.api_server_url;
  const tsgId = authData?.tsg_id;
  if (!apiServerUrl || !tsgId) {
    return undefined;
  }

  return {
    serverURL: apiServerUrl,
    apiMethod: `/api/v3/t/${tsgId}`,
    headers: {
      "prisma-tenant": tsgId
    },
    tsgId
  };
}

export function initV2Nav(sparkyFramework: any, vars: any) {
  import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch")
    .then(({ prefetch }) => {
      const params = getParams(sparkyFramework, vars, undefined /* use v2 routes by default */, sparkyFramework.actions.setValues);
      prefetch(...params);
    })
    .catch((err) => {
      console.error("Error in app initialization:", err);
    });
}

let reporting = {};
export async function initV3Nav(sparkyFramework: any, vars: any): Promise<object> {
  const apiPrefetchRes = await import("@reporting-n-viz/vnr-entrypoint/dist/api-prefetch").catch((e) => {
    console.log('Error fetching api-prefetch', e);
  });
  const { prefetch } = apiPrefetchRes;
  if (prefetch) {
    const params = getParams(sparkyFramework, vars, true, (params: any) => {
      reporting = params.reporting;
    }) as Parameters<(apiEndpoint: string, params: any, callback: (response: any) => void) => Promise<void>>;
    await prefetch(...params);
  }
  return reporting;
}
