/* eslint-disable @typescript-eslint/no-var-requires */

import { getState } from "@sparky/framework";
import { SPARKY_NAV3_DYNAMIC_TOP_LEFT_EXTPOINT } from '@sparky/framework/extpoints';

export default {
  point: SPARKY_NAV3_DYNAMIC_TOP_LEFT_EXTPOINT,
  configs: [
    {
      id: 'custom-dashboards',
      config: {
        stateLoaders: ['auth', 'reporting'],
        title: "Security Insights",
        key: 'security-insights',
        path: '/security-insights',
        isAllowed: () => {
          return true;
        },
        children: [
          {
            title: "Custom Dashboards",
            key: 'custom-dashboards', // no path
            avail: () => {
              const { reporting = {} } = getState() || {};
              const reports = reporting?.reportsById;
              const customReportsArray = Object.keys(reports ?? {})
                .filter(key => reports[key].group === "custom" && reports[key].allowed === true && key !== "dashboard_editor" && key !== "widget_viewer")
                .map(key => reports[key]);
              return customReportsArray.length > 0;
            },
            dynamicChildren: () => {
              const { reporting = {} } = getState() || {};
              const reports = reporting?.reportsById;
              const customReportsArray = Object.keys(reports ?? {})
                .filter(key => reports[key].group === "custom" && reports[key].allowed === true && key !== "dashboard_editor" && key !== "widget_viewer")
                .map(key => {
                  reports[key].title = reports[key].name.defaultMessage;
                  return reports[key];
                });
              return customReportsArray;
            }
          }
        ]
      }
    }
  ]

};
