export type ZtnaRouteGizmo = { ztna_route_urls: ReturnType<typeof ztnaRoutes> }
export type OnBoardingRouteGizmo = { onboarding_route_urls: ReturnType<typeof onBoardingRoutes> }

export function ztnaRoutes(baseUrl?: string) {
	return {
		baseUrl,
		overview: `${baseUrl}/overview`,
		applications: `${baseUrl}/app-targets`,
		cloud_accounts: `${baseUrl}/cloud-accounts`,
		fqdns: `${baseUrl}/fqdns`,
		subnets: `${baseUrl}/subnets`,
		wildcards: `${baseUrl}/wildcards`,
		targetdiscovery: `${baseUrl}/target-discovery`,
		connectors: `${baseUrl}/connectors`,
		ngfwConnectors: `${baseUrl}/ngfw-connectors`,
		connectorGroups: `${baseUrl}/connector-groups`,
	} as const
}

export function onBoardingRoutes(baseUrl?: string) {
	return {
		baseUrl,
		privateApps: `${baseUrl}/private-apps`,
		bluebird: `${baseUrl}/private-apps/ztna-connector`,
	} as const
}

/**
 * ZTNA routes on Prisma Access.
 *
 * @note This routes object violates component design,
 * as this package should not be aware of where it is being deployed.
 * (e.g. as a sparky micro app in Prisma Access, or as a standalone app).
 *
 * We violate the design here to make it easy to be consumed by any app on Fawkes.
 * An alternative is to create a simple wrapper package with this routes.
 *
 * But at this moment that is a bit overkilled.
 */
export const ztnaPrismaAccessRoutes = ztnaRoutes('/workflows/ztna-connector')
export const OnboardingPrismaAccessRoutes = onBoardingRoutes('/workflows/onboarding')

