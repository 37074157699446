import { getState, getAuthState, getFrameworkVars } from "@sparky/framework";
import { SPARKY_NAV3_ROUTES_EXTPOINT } from '@sparky/framework/extpoints';
import { REPORTING_ASSOCIATED_APPS } from "../ReportingAssociatedApps";
import type { ExtensionsConfig, SparkyNavRouteConfig, StoreState } from "@sparky/framework/extpoints";

function getReportsById () {
  const { reporting = {} } = getState() || {};
  return reporting?.reportsById;
}
function hideReporting () {
  // disable dashboards/reporting for enterprise dlp or casb only tenants
  // load reporting pages only if associated apps are present
  const instances = getAuthState()?.instances;
  const found = REPORTING_ASSOCIATED_APPS.some(app => instances?.isRunningStatus(app));
  if (!found) {
    return true;
  }
  return false;
}
const isUserPreferencesSupported = Boolean(getFrameworkVars()?.user_preferences_ui);
let theme = "";
if (!isUserPreferencesSupported) {
  theme = "sparky-dark-only";
}
if (getFrameworkVars()?.pdf_mode) {
  theme = "sparky-light-only";
}

const contentClassName = "reports-root panwds-tw3" + theme;

export default {
  point: SPARKY_NAV3_ROUTES_EXTPOINT,
  configs: [
    {
      id: 'netsec.vnr.routes.security.insights.actvity.insights',
      config: {
        path: '/security-insights/activity_insights',
        replacePaths: '/insights/activity_insights',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: (state: StoreState) => {
          return {
            main: state.main,
            id: "unified_network_activity_report",
            reporting: state.reporting,
            leftMainNavigation: state.leftMainNavigation,
            theme:
                            state.theme === "light" || state.theme === "dark"
                              ? state.theme
                              : "light"
          };
        },
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../UnifiedNetworkActivity"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();
          return reportById ? reportById.unified_network_activity_report.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.threats.intelligence',
      config: {
        path: '/security-insights/threats-intelligence',
        replacePaths: '/monitor/search',

        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Search"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();

          return reportById ? reportById.ioc_report.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.threat.insights',
      config: {
        path: '/security-insights/threat_insights',
        replacePaths: '/dashboards/threat_insights',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          if (hideReporting()) {
            return false;
          }
          const reportById = getReportsById();

          return reportById ? reportById?.threat_insights_report.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.executive',
      config: {
        path: '/security-insights/executive',
        replacePaths: '/dashboards/executive',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.executive_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.app.usage',
      config: {
        path: '/security-insights/app_usage',
        replacePaths: '/dashboards/app_usage',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.app_usage_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.usage',
      config: {
        path: '/security-insights/usage',
        replacePaths: '/dashboards/usage',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.prisma_access_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.uar',
      config: {
        path: '/security-insights/uar',
        replacePaths: '/dashboards/uar',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.user_activity_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.wildfire',
      config: {
        path: '/security-insights/wildfire',
        replacePaths: '/dashboards/wildfire',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.wildfire_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.insights.network.activity',
      config: {
        path: '/security-insights/network_activity',
        replacePaths: '/dashboards/network_activity',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.network_activity_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.security.bpa',
      config: {
        path: '/security-insights/bpa',
        replacePaths: '/dashboards/bpa',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: () => ({ }),
        contentClassName, // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import("../Dashboard"), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById?.best_practices_report?.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.logviewer',
      config: {
        path: '/logviewer',
        replacePaths: '/incidents-alerts/logviewer',
        stateLoaders: ['auth', 'reporting'],
        // exact: false,
        mapStateToProps: (state: StoreState) => {
          return {
            main: state.main,
            flattenNavigationList:
                            state.leftMainNavigation?.flattenNavigationList ?? [],
            leftMainNavigation: state.leftMainNavigation,
            reporting: state.reporting,
            theme:
                            state.theme === "light" || state.theme === "dark"
                              ? state.theme
                              : "light"
          };
        },
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../LogViewer'), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById.log_viewer.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.dashboard.editor',
      config: {
        path: '/security-insights/dashboard_editor/:dashboardId?',
        replacePaths: '/dashboard_editor/:dashboardId?',
        stateLoaders: ['auth', 'reporting'],
        mapStateToProps: () => ({ }),
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../Dashboard'), // any component must be dynamically import like this
        isAllowed: () => {
          const reportById = getReportsById();
          return reportById ? reportById.dashboard_editor.allowed : false;
        }
      }
    },
    {
      id: 'netsec.vnr.routes.custom.dashboard',
      config: {
        path: '/security-insights/dashboards/:dashboardId?',
        replacePaths: '/dashboards/:dashboardId?',
        stateLoaders: ['auth', 'reporting'],
        mapStateToProps: () => ({ }),
        contentClassName: 'panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
        component: async () => await import('../Dashboard'), // any component must be dynamically import like this
        isAllowed: () => {
          return true;
        }
      }
    }
  ]
} satisfies ExtensionsConfig<SparkyNavRouteConfig>;
