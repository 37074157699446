/* eslint-disable @typescript-eslint/no-var-requires */
import { getState, getAuthState } from "@sparky/framework";
import { getTenantInfo } from "./utils/get-tenant-info";
import { initV2Nav } from "./init";

/* Warning: please do not statically import any dependencies here except for static assets like icons */

const init = async ({ vars, sparkyFramework }: any) => {
  return async function tsgLoad() {
    console.log(
      `%c@reporting-n-viz/reporting_oneapp: 22 Nov`,
      "display: block; font-size: 21px; border: 2px solid teal; border-radius: 8px; padding: 16px; margin: 16px"
    );
    initV2Nav(sparkyFramework, vars);
    return await import(/* webpackPreload: true */ "./routes").then((r) => r.default);
  };
};

export const exposes = {
  default: {
    UARComponent: async () => await import("./UAR"),
    Dashboard: async () => await import("./Dashboard"),
    CoPilot: async () => await import("./CoPilot")
  },
  copilot_available_paths: {
    vnr() {
      const authData = getAuthState();
      const tenantInfo = getTenantInfo(authData);
      const globalContext = {
        tenantInfo,
        pdfMode: false,
        licenseInfo: {
          CDL: {
            status: tenantInfo.hasCDL ? "active" : "noLicense"
          }
        }
      };
      const reportsById = getState()?.reporting?.reportsById ?? {};
      const aiAllowed = reportsById?.unified_network_activity_report?.allowed ?? false;
      return [
        {
          version: "v2",
          path: `/insights/activity_insights/summary`,
          title: "Activity Insights (Overview)",
          isAllowed: () => {
            return (
              aiAllowed &&
              !globalContext.pdfMode &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/apps",
          title: "Activity Insights (Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "View your application details, performance analytics, and insights to optimize productivity and security resource allocation."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/sdwan_apps",
          title: "Activity Insights (SDWAN Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.tenantInfo?.hasSDWAN &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description: "View your SD-WAN Application details, performance analytics and insights"
        },
        {
          version: "v2",
          path: "/insights/activity_insights/threats",
          title: "Activity Insights (Threats)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Holistic view of the threat environment in your network, with comprehensive analytics to enable you to act and confidently protect yourself."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/users",
          title: "Activity Insights (Users)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "Empowering insights into user behavior, application preferences, and security risks for data-driven decision-making and improved user experience."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/urls",
          title: "Activity Insights (URLs)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Insights into web browsing behavior, URL filtering for security, and data-driven web policies to enhance user web experience and protection."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/rules",
          title: "Activity Insights (Rules)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Overview of your network rule landscape, automated security responses, and streamlined network policies that allow you to ensure efficient network governance."
        },
        {
          version: "v2",
          path: "/insights/activity_insights/traffic",
          title: "Activity Insights (Regions)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        },
        // SCM NAV V3
        {
          version: "v3",
          path: `/security-insights/activity-insights/summary`,
          title: "Activity Insights (Overview)",
          isAllowed: () => {
            return (
              aiAllowed &&
              !globalContext.pdfMode &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/apps",
          title: "Activity Insights (Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "View your application details, performance analytics, and insights to optimize productivity and security resource allocation."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/sdwan_apps",
          title: "Activity Insights (SDWAN Applications)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.tenantInfo?.hasSDWAN &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description: "View your SD-WAN Application details, performance analytics and insights"
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/threats",
          title: "Activity Insights (Threats)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Holistic view of the threat environment in your network, with comprehensive analytics to enable you to act and confidently protect yourself."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/users",
          title: "Activity Insights (Users)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant
            );
          },
          description:
            "Empowering insights into user behavior, application preferences, and security risks for data-driven decision-making and improved user experience."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/urls",
          title: "Activity Insights (URLs)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Insights into web browsing behavior, URL filtering for security, and data-driven web policies to enhance user web experience and protection."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/rules",
          title: "Activity Insights (Rules)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Overview of your network rule landscape, automated security responses, and streamlined network policies that allow you to ensure efficient network governance."
        },
        {
          version: "v3",
          path: "/security-insights/activity-insights/traffic",
          title: "Activity Insights (Regions)",
          isAllowed: () => {
            return (
              aiAllowed &&
              globalContext?.licenseInfo?.CDL?.status === "active" &&
              !globalContext?.tenantInfo?.isSDWANOnlyTenant &&
              !globalContext?.tenantInfo?.isADEMTier1Role
            );
          },
          description:
            "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network."
        }
      ];
    }
  }
};

export default init;

export { default as extensions } from "./extensions";
